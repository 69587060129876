import React from "react";
import { Nav, Navbar } from "react-bootstrap";


const NavBar = () => {
    return (
        <header className='mobileNav'>
            <Navbar collapseOnSelect expand="lg" variant="dark" className="px-3" sticky="top">
                <Navbar.Brand>
                    <a class="navbar-brand" href="#home" >
                        <img src="./assets/images/cbt-logo.png" alt="" width="64" height="64"></img>
                    </a>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                    <Nav className="mr-auto">
                        <Nav.Link href="#home">HOME</Nav.Link>
                        <Nav.Link href="#services">SERVICES</Nav.Link>
                        <Nav.Link href="#contact">CONTACT</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </header>

    );
}
export default NavBar;