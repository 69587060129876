import React from "react";
import emailjs from 'emailjs-com';
import { Parallax } from "react-parallax";
import { Form, Input, TextArea, Button } from 'semantic-ui-react';
import Swal from 'sweetalert2';
import { init } from 'emailjs-com';
init("user_BlfQMpiMM4jFFlqngCIQH");
const image2 = "./assets/images/image2.jpg"

const ContactForm = () => {
    
    const SERVICE_ID = "service_cdxr79h";
    const TEMPLATE_ID = "template_5hg9gag";
    const USER_ID = "Te_p51LRltj3YegCY";
    const handleOnSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
            .then((result) => {
                console.log(result.text);
                Swal.fire({
                    icon: 'success',
                    title: 'Message Sent Successfully'
                })
            }, (error) => {
                console.log(error.text);
                Swal.fire({
                    icon: 'error',
                    title: 'Ooops, something went wrong',
                    text: error.text,
                })
            });
        e.target.reset()
    };
    return (
        <div className='App'>
            <Parallax bgImage={image2} strength={-200} blur={{ min: -15, max: 10 }}>
                <div style={{ height: 450 }}>
                </div>
            </Parallax>
            <div className="bg-white m-5 py-5 d-flex justify-content-center">
                <div className="text-start py-3">
                    <h2 className="text-dark fs-2 fw-bold text-end">LEARN MORE ABOUT</h2>
                    <h2 className="text-danger border-bottom border-dark fw-bold text-end">CARDINAL BRIDGE TECHNOLOGY LLC</h2>
                    <div className="text-end py-3">
                        <h5 className="text-dark text-end">To reach us; fill out our form below or</h5>
                        <h5 className="text-dark text-end">call us direclty at <a href="tel:1-234-360-2400">234.360.2400</a>.</h5>
                    </div>
                </div>
            </div>
            <Form className="bg-light rounded-0 p-5" onSubmit={handleOnSubmit}>
                <div className='emails'>
                <Form.Field
                    id='form-input-control-email'
                    control={Input}
                    label='Email:'
                    name='user_email'
                    placeholder='Email…'
                    required
                    icon='mail'
                    iconPosition='left'
                    className="my-4"
                />
                <Form.Field
                    id='form-input-control-last-name'
                    control={Input}
                    label='Name:'
                    name='user_name'
                    placeholder='Name…'
                    required
                    icon='user circle'
                    iconPosition='left'
                    className="my-4"
                />
                <Form.Field
                    id='form-textarea-control-opinion'
                    control={TextArea}
                    label='Your Information:'
                    name='user_message'
                    placeholder='Message…'
                    required
                    className="my-4"
                />
                </div>
                <Button type='submit'>Submit</Button>
            </Form>
        </div>
    );
}
export default ContactForm;