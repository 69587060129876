import React from "react";

const ServiceForm = () => {
    return (
        <div className='App'>
            <div className="navSpace" bg="white"></div>
            <div className="container">
                <div className="row">
                    <div className="col p-0 m-0 d-none d-md-block">
                        <img src="./assets/images/13Slant.png" className="img-fluid" alt="mobile phone with programming code"></img>
                    </div>
                    <div className="navSpaceMobile" bg="white"></div>
                    <div className="col p-4 my-auto">
                        <h3 className="text-end text-danger border-bottom border-dark fs-2 fw-bold">CUSTOM IT SOLUTIONS</h3>
                        <p className="text-dark text-end"> Whether your organization needs a network refresh, new infrastructure, or help navigating through a software migration, CBT can help.  CBT has experience with physical & virtual infrastructure, enterprise licensing, and managing overall IT needs.
                        </p>
                    </div>
                    <div className="navSpaceMobile" bg="white"></div>
                </div>
            </div>
            <div className="bottomNavSpace" bg="white"></div>
            <div className="bg-light text-white p-5">
                <div className="container p-4">
                    <div className="row row-cols-1 justify-content-evenly row-cols-md-2 g-5 m-1">
                        <div className="">
                            <h3 className="fw-bold">DATACENTER MIGRATION</h3>
                            <p className="text-dark">CBT specializes in datacenter migrations, so you don’t have too.Migrate your datacenter to a new physical location, move to a virtual platform, or plan your evolution to cloud services. We handle the project planning, vendor relations, and even implementation.
                            </p>
                        </div>
                        <div className="">
                            <h3 className="fw-bold">DATACENTER DESIGN</h3>
                            <p className="text-dark">Planning, Design, and Implantation of a Datacenter/IT Solution is a big step in growing your business. CBT can design, implement, and even manage your datacenter needs, keeping your company secure and efficient.
                            </p>
                        </div>
                    </div>
                    <div className="row row-cols-1 justify-content-evenly row-cols-md-2 g-5 m-1">
                        <div className="">
                            <h3 className="fw-bold">DATACENTER BCP/DR</h3>
                            <p className="text-dark">Are you ready for a disaster recovery event or have a business continuity plan? Keep your enterprise going and still meet your SLAs for your clients. CBT can create your DR and BCP plans to meet your business needs, as well as your customers’ expectations.
                            </p>
                        </div>
                        <div className="">
                            <h3 className="fw-bold">MANAGED SERVICES</h3>
                            <p className="text-dark">IT consulting for your enterprise or small business. CBT can manage your IT needs, provide infrastructure support, and manage IT vendor relation services.
                            </p>
                        </div>
                    </div>
                    <div className="row row-cols-1 justify-content-evenly row-cols-md-2 g-5 m-1">
                        <div className="">
                            <h3 className="fw-bold">WEB DEVELOPMENT SERVICES</h3>
                            <p className="text-dark">Want to refresh your current website, add mobile responsiveness, or create a modern web application to streamline your employee workflow? At CBT, we can create a fresh, custom web application to support your growing business.
                            </p>
                        </div>
                        <div className="">
                            <h3 className="fw-bold">SITE RECOVERY SERVICES</h3>
                            <p className="text-dark">CBT can help you regain access to your original website, email, and domains so you can regain control of your companies’ online assets.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ServiceForm;