import React from "react";
import NavBar from "./components/NavBar";
import ContactForm from "./components/ContactForm";
import ServiceForm from "./components/ServiceForm";
import Hero from "./components/Hero";
// import useSticky from "./hooks/useSticky";
import "./App.css"


export default function App() {
  // const { isSticky, element } = useSticky()
  return (
    <>
      <div>
        <NavBar />
      </div>
      <div id="home">
        <Hero />
      </div>
      <div id="services">
        <ServiceForm />
      </div>
      <div id="contact">
        <ContactForm />
      </div>
    </>
  );
}