import React from "react";
import { Parallax } from "react-parallax";

const image1 = "./assets/images/xDrk_bridge_in_cleveland_ohio.jpg";

const Hero = () => {
    return (
        <div className='App'>
            <Parallax
                bgImage={image1}
                strength={-500}
            >
                <div className="paraSize">
                    <div className="centerContent">
                        <h1 className="TitleStuff">CARDINAL BRIDGE</h1>
                        <h1 className="TitleStuff"> TECHNOLOGY LLC</h1>
                        <h5 className="subTitleStuff text-light">Providing Technology Services to the</h5>
                        <h5 className="subTitleStuff text-light">Greater Cleveland/Akron area. </h5>
                    </div>
                </div>
            </Parallax>
        </div>

    );
}
export default Hero;